<template>
  <pl-block>
    <el-button :loading="loading.refreshInventory" @click="refreshInventory">
      刷新货主货品库存
    </el-button>
  </pl-block>
</template>
<script>
import loadingMixin from '@thales/loading';
import shipperGoodsStockGenerate from './api';

export default {
  name: 'ConfigManagement',
  mixins: [loadingMixin],
  data() {
    return {
      loading: {
        refreshInventory: false,
      },
    };
  },
  methods: {
    async  refreshInventory() {
      await shipperGoodsStockGenerate();
      this.$message({ type: 'success', message: '刷新成功！' });
    },
  },
};
</script>
