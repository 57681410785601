import { wmsPlusHttp } from '@/utils/http/index';

/**
 * @description: 刷新货主货品库存(内部接口，api无地址，直接问李一航或者雷旭梦)
 */

function shipperGoodsStockGenerate() {
  return wmsPlusHttp.post('/warehouse_management_system/private/shipper_goods_stock/generate', {});
}
export default shipperGoodsStockGenerate;
